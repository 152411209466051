// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Tabler
@import '~@tabler/core/src/scss/tabler';
@import 'jquery-toast-plugin';
@import 'sweetalert2/src/sweetalert2.scss';
@import 'dropify/dist/css/dropify';

// Variables
//@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

a{outline: 0}